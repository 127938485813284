import React, {useEffect, useMemo, useState} from 'react'
import {useSelector} from 'react-redux';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {cloneDeep, take} from 'lodash';
import {InputNumber, message} from 'antd'

import './index.less'
import './mobile.less'

import Layout from '@/components/Layout'
import ProductItem from '@/components/ProductItem'
import OSwiper from 'src/components/OSwiper'
import noImage from '@/assets/img/icon/noImage.png'
import favoriteActiveIcon from '@/assets/img/icon/favoriteActive.png';
import favoriteDefaultIcon from '@/assets/img/icon/favoriteDefault.png';

import {
  addProductToFavoriteList,
  getMoreProducts,
  getProductDetail,
  removeProductFromFavoriteList
} from '@/api/req-api';
import {
  checkLogin, getEditOrderProductList,
  getFormatMoney,
  getFormatProductListByCartList,
  getProductItemBtnType, setEditOrderIsGetOrderDetails,
  setEditOrderProductList
} from '@/common';

export default function EditOrderProductDetail() {
  const {id} = useParams();
  const navigate = useNavigate()
  const location = useLocation()
  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const {productCategoryMap, isLogged} = commonState
  const rootClassName = commonState.isMobile ? 'edit-order-product-detail-mobile-container' : 'edit-order-product-detail-container'
  const [productDetail, setProductDetail] = useState({})
  const [moreProducts, setMoreProducts] = useState([])
  const [quantity, setQuantity] = useState(1);
  const [productList, setProductList] = useState([])
  const [editOrderProducts, setEditOrderProducts] = useState([])
  const getEditOrderProductListByLocal = () => {
    setEditOrderProducts(getEditOrderProductList())
  }

  useEffect(() => {
    getEditOrderProductListByLocal()
    setEditOrderIsGetOrderDetails(false)
  }, [])

  const useMoreProducts = useMemo(() => {
    return getFormatProductListByCartList(moreProducts, editOrderProducts)
  }, [moreProducts, editOrderProducts])

  const useProductList = useMemo(() => {
    return getFormatProductListByCartList(productList, editOrderProducts)
  }, [productList, editOrderProducts])
  
  const fetchDetail = async (id) => {
    try {
      const {data: [productDetailResponse]} = await getProductDetail(id)
      setProductDetail(productDetailResponse)
      setProductList(productDetailResponse.related_products || [])
      const {data: moreProductResponse} = await getMoreProducts(productDetailResponse.ProductGroup_id, productDetailResponse.Product_id)
      setMoreProducts(take(moreProductResponse, 8))
    } catch (e) {
      //todo show error 
    }
  }
  const initPage = () => {
    if (id) {
      fetchDetail(id)
    }
  }
  useEffect(() => {
    initPage()
  }, [id])

  const showFavoriteIcon = productDetail.IsFavorite ? favoriteActiveIcon : favoriteDefaultIcon

  const renderProductImgSwiperSlideContent = (item, index) => {
    return <div
      className={'edit-order-product-detail-top-info-left-img-container'}
      key={`product_detail_img_${index}`}>
      <img src={item} alt=""/>
    </div>
  }
  const renderProductImgSwiper = () => <OSwiper
    className={'edit-order-product-detail-top-info-left-product-img-swiper-container'}
    swiperClassName={'edit-order-product-detail-top-info-left-product-img-swiper-swiper'}
    prevClassName={'edit-order-product-detail-top-info-left-product-img-swiper-prev'}
    nextClassName={'edit-order-product-detail-top-info-left-product-img-swiper-next'}
    data={productDetail?.Images && productDetail.Images.length > 0 ? productDetail.Images : [noImage]}
    renderSwiperSlideContent={renderProductImgSwiperSlideContent}
    isShowPagination={true}
    isShowNavigation={false}
  />

  const renderSwiperSlideContent = (item, index) => {
    return <ProductItem
      img={item.Thumbnail}
      price={item.Price}
      des={item.Name}
      isShowPrice={isLogged}
      isFormatPriceToMoney={true}
      onBtnClick={() => addProductToOrderProductList(item, 1)}
      onProductItemClick={() => handleProductClick(item)}
      isFavorited={item.IsFavorite}
      isShowFavorite={true}
      onFavoriteIconClick={() => handleFavoriteIconClick(item)}
      quantity={item.Quantity}
      btnType={getProductItemBtnType(item.Quantity)}
      onMinBtnClick={() => handleMinBtnClick(item, item.Quantity)}
      onPlusBtnClick={() => addProductToOrderProductList(item)}
      isPromotion={item.IsPromotion}
      stock={item.StockLevel}
      isOverSaleable={item.IsOverSaleable}
    />
  }
  const renderProductList = () => <OSwiper
    className={'edit-order-product-detail-top-product-list-container'}
    data={useProductList}
    renderSwiperSlideContent={renderSwiperSlideContent}
    slidesPerView={commonState.isMobile ? 2 : 4}
  />

  const handleMinBtnClick = (item, qty) => {
    const newQty = qty - 1
    if (newQty < 1) {
      return removeProductByOrderProductList(item)
    }
    editProductByOrderProductList(item, newQty)
  }

  const handleChangeFavoriteStatus = (item) => {
    const isFavorite = item.IsFavorite
    const msg = isFavorite ? t('Cancel favorite successfully') : t('Add favorite successfully')
    const fun = isFavorite ? removeProductFromFavoriteList : addProductToFavoriteList
    fun(item).then(() => {
      message.success(msg)
      initPage()
    })
  }
  const handleBeforeChangeFavoriteStatus = (item, callback) => {
    checkLogin(
      navigate,
      isLogged,
      location,
      callback
    )
  }
  const handleFavoriteIconClick = (item, isProductDetail = false) => {
    handleBeforeChangeFavoriteStatus(item, () => handleChangeFavoriteStatus(item, isProductDetail))
  }
  
  const handleProductClick = item => {
    const path = `/editOrderProductDetail/${item.ProductNum}`
    // if (location.pathname === path) {
    //   return
    // }
    navigate(path);
  }

  const addProductToOrderProductList = (productItem = {}, quantity = 1) => {
    const productList = cloneDeep(editOrderProducts)
    productItem.Quantity = (productItem.Quantity || 0) + quantity
    const idx = productList.findIndex(item => item.Product_id === productItem.Product_id)
    if (idx < 0) {
      productList.push(productItem)
    } else {
      productList.splice(idx, 1, productItem)
    }
    setEditOrderProducts(productList)
    message.success(t('Added successfully'))
  }
  const editProductByOrderProductList = (productItem = {}, quantity = 1) => {
    const productList = cloneDeep(editOrderProducts)
    productItem.Quantity = quantity
    const idx = productList.findIndex(item => item.Product_id === productItem.Product_id)
    if (idx < 0) {
      return
    }
    productList.splice(idx, 1, productItem)
    setEditOrderProducts(productList)
  }
  const removeProductByOrderProductList = (productItem = {}) => {
    const productList = cloneDeep(editOrderProducts)
    const idx = productList.findIndex(item => item.Product_id === productItem.Product_id)
    if (idx < 0) {
      return
    }
    productList.splice(idx, 1)
    setEditOrderProducts(productList)
  }
  
  const renderProductGroupItem = (item, index) => <div
    className={'edit-order-product-detail-more-product-item-container'}
    key={`product_group_2_item_${index}`}>
    <ProductItem 
        img={item.Thumbnail} 
        price={item.Price} 
        des={item.Name} 
        isShowPrice={isLogged}
        isFormatPriceToMoney={true}
        onBtnClick={() => addProductToOrderProductList(item, 1)}
        onProductItemClick={() => handleProductClick(item)}
        isFavorited={item.IsFavorite}
        isShowFavorite={true}
        onFavoriteIconClick={() => handleFavoriteIconClick(item)}
        quantity={item.Quantity}
        btnType={getProductItemBtnType(item.Quantity)}
        onMinBtnClick={() => handleMinBtnClick(item, item.Quantity)}
        onPlusBtnClick={() => addProductToOrderProductList(item)}
        isPromotion={item.IsPromotion}
        stock={item.StockLevel}
        isOverSaleable={item.IsOverSaleable}
    />
  </div>
  const renderRichTextDescription = () => {
    if (!!productDetail?.rich_text_description) {
      return <div className={'edit-order-product-detail-rich-text-description-container'}>
        <div className={'edit-order-product-detail-rich-text-description-title'}>{t('Description')}</div>
        <div className="product-detail-rich-text-description-content" dangerouslySetInnerHTML={{__html: productDetail?.rich_text_description}}/>
      </div>
    }
  }
  const renderMoreProduct = () => <div className={'edit-order-product-detail-more-product-container'}>
    <div className={'edit-order-product-detail-more-product-title'}>{t('MORE PRODUCT')}</div>
    <div className={'edit-order-product-detail-more-product-list'}>
      {useMoreProducts.map((item, index) => renderProductGroupItem(item, index))}
    </div>
  </div>
  const handleConfirmClick = () => {
    setEditOrderProductList(editOrderProducts)
    navigate(-2) //todo 
  }
  const handleCancelClick = () => {
    navigate(-2) //todo
  }
  const isOutOfStock = productDetail?.IsOverSaleable ? false : productDetail?.StockLevel <= 0
  const hasPromotion = productDetail?.DiscountDescription && productDetail?.DiscountDescription?.length > 0;
  return (
    <Layout
      isDetailPage={true}
      isShowHeaderRight={false}
      detailPageTitle={t('Select More')}>
      <div className={rootClassName}>
        <div className={'edit-order-product-detail-top-banner-container'}>
          <div className={'edit-order-product-detail-btn-1'} onClick={handleConfirmClick}>
            {t('Confirm')}
          </div>
          <div className={'edit-order-product-detail-btn-2'} onClick={handleCancelClick}>
            {t('Cancel')}
          </div>
        </div>
        <div className={'edit-order-product-detail-top-container'}>
          <div className={'edit-order-product-detail-top-title'}>
            <span>{productDetail?.ProductCategory1_id ? productCategoryMap[productDetail.ProductCategory1_id]?.Name : ''}</span>
            <div>{productDetail?.Name}</div>
          </div>
          <div className={'edit-order-product-detail-top-info-container'}>
            <div className={'edit-order-product-detail-top-info-left-container'}>
              {renderProductImgSwiper()}
              <div
                className={'edit-order-product-detail-top-info-left-favorite-icon-container'}
                onClick={() => handleFavoriteIconClick(productDetail, true)}>
                <img src={showFavoriteIcon} alt="" />
              </div>
            </div>
            <div className={'edit-order-product-detail-top-info-right-container'}>
              <div className={'edit-order-product-detail-top-info-right-ref-no'}>{t('Ref No')}#: {productDetail?.RefNum}</div>
              {
                isLogged && <div className={'edit-order-product-detail-top-info-right-price'}>
                  {`$${getFormatMoney(productDetail?.Price) ?? ''}`}
                  <span>&nbsp;/ {productDetail?.MeasureUnit}</span>
                </div>
              }
              {hasPromotion && <div className={'edit-order-detail-top-info-right-product-promotion-container'}>
                <div className={'edit-order-detail-top-info-right-product-promotion-title'}>
                  {t('Promotion')}
                </div>
                <div className={'edit-order-detail-top-info-right-product-promotion-item'}>
                  {productDetail?.DiscountDescription.map(description => <div>
                    {description}
                  </div>)}
                </div>
              </div>}
              <div className={'edit-order-product-detail-top-info-right-product-description-container'}>
                <div className={'edit-order-product-detail-top-info-right-product-description-title'}>
                  {t('edit-order-product Description')}
                </div>
                <div className={'edit-order-product-detail-top-info-right-product-description-item'}>
                  {productDetail?.Description}
                </div>
              </div>
              <div className={'edit-order-product-detail-top-info-right-bottom-container'}>
                <div className={'edit-order-product-detail-top-info-right-bottom-left-container'}>
                  <div className={'edit-order-product-detail-top-info-right-bottom-left-label'}>{t('QTY')}</div>
                  <InputNumber
                    className={'edit-order-product-detail-top-info-right-bottom-left-input'}
                    min={1}
                    max={productDetail?.IsOverSaleable ? undefined : productDetail?.StockLevel}
                    defaultValue={quantity}
                    onChange={setQuantity}
                    disabled={isOutOfStock}
                  />
                </div>
                {isOutOfStock ? <div
                    className={'edit-order-detail-top-info-right-bottom-btn-disabled'}>
                  {t('Out of stock')}
                </div> : <div
                  className={'edit-order-product-detail-top-info-right-bottom-btn'}
                  onClick={() => addProductToOrderProductList(productDetail, quantity)}>
                  {t('Add to cart')}
                </div>}
              </div>
            </div>
          </div>
          {renderProductList()}
        </div>
        {renderRichTextDescription()}
        {renderMoreProduct()}
      </div>
    </Layout>
  )
}
