import React from 'react'
import {useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next'

import './index.less'
import './mobile.less'

import WechatPopover from '@/components/WechatPopover'
import logoImg from '@/assets/img/logo.png'
import wechatImg from '@/assets/img/icon/wechat.png'
import instagramImg from '@/assets/img/icon/instagram.png'
import facebookImg from '@/assets/img/icon/facebook.png'
import {FACEBOOK_LINK} from '@/constants';
import wechatImgGoogle from '@/assets/img/olongGoogle.png'
import wechatImgApple from '@/assets/img/olongApple.png'
import h5ImgIos from '@/assets/img/icon/ios.png'
import h5ImgGoogle from '@/assets/img/icon/google.png'
const defaultProps = {
  isShowFooterTop: true,
};

export default function Footer(props) {
  const options = Object.assign({}, defaultProps, props);
  
  const navigate = useNavigate()
  const location = useLocation()
  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'footer-mobile-container' : 'footer-container'
  
  const handleContactUsClick = () => {
    const path = '/contactUs'
    if (location.pathname === path) {
      return
    }
    navigate(path)
  }
  
  return (
    <div className={rootClassName} id={'page-footer'}>
      {
        options.isShowFooterTop &&
        <div className={'footer-top-container'}>
          <div className={'footer-top-content-container'}>
            <div className={'footer-top-content-left-container'}>
              <div className={'footer-top-content-left-logo-container'}>
                <img src={logoImg} alt="logo" />
              </div>
              <div className={'footer-top-content-left-des'}>
                Olong Trading Inc, located at 31 Ave. in Faraching, (next to Highway 678, near the university site, next to the DMV supervision station), reopened on (9/18). We specialize in providing hundreds of high-quality goods such as seafood, vegetables, meat, dry goods, seasonings and lunch boxes.
              </div>
              <div className={'footer-top-content-left-get-in-touch-container'}>
                <div className={'footer-top-content-title'}>{t('SOCIAL MEDIAS')}</div>
                <div className={'footer-top-content-left-get-in-touch-list'}>
                  <WechatPopover>
                    <div className={'footer-top-content-left-get-in-touch-item-container'}>
                      <img src={wechatImg} alt="wechat" />
                    </div>
                  </WechatPopover>
                  <div className={'footer-top-content-left-get-in-touch-item-container'}>
                    <img src={instagramImg} alt="instagram" />
                  </div>
                  <div
                    className={'footer-top-content-left-get-in-touch-item-container'}
                    onClick={() => window.open(FACEBOOK_LINK)}>
                    <img src={facebookImg} alt="facebook" />
                  </div>
                </div>
              </div>
              <div className={'footer-top-content-left-get-in-touch-container'}>
                <div className={'footer-top-content-title'}>{t('Download App')}</div>
                { !commonState.isMobile && <div className={'footer-top-content-left-get-in-touch-list footer-top-content-left-download-list'}>
                  <div className={'footer-top-content-left-get-in-touch-item-container footer-top-content-left-download-container'}>
                    <div><img src={wechatImgApple} alt="instagram" /></div>
                    <div className={'footer-top-content-left-download-type'}>{t('ios')}</div>
                  </div>
                  <div className={'footer-top-content-left-get-in-touch-item-container footer-top-content-left-download-container'}>
                    <div><img src={wechatImgGoogle} alt="instagram" /></div>
                    <div className={'footer-top-content-left-download-type'}>{t('android')}</div>
                  </div>
                </div>}
                { commonState.isMobile && <div className={'footer-top-content-left-get-in-touch-list footer-top-content-left-download-list'}>
                  <div className={'footer-top-content-left-get-in-touch-item-container footer-top-content-left-download-container'}>
                    <a href='https://apps.apple.com/us/app/olong/id6475374905'><img src={h5ImgIos} alt="instagram" /></a>
                  </div>
                  <div className={'footer-top-content-left-get-in-touch-item-container footer-top-content-left-download-container'}>
                    <a href='https://play.google.com/store/apps/details?id=com.olongmobileapp'><img src={h5ImgGoogle} alt="instagram" /></a>
                  </div>
                </div>
                }
              </div>
            </div>
            <div className={'footer-top-content-right-container'}>
              <div className={'footer-top-content-right-working-hours-container'}>
                <div className={'footer-top-content-title'}>{t('WORKING HOURS')}</div>
                <div className={'footer-top-content-right-working-hours-item-container'}>
                  {t('Monday - Saturday')}
                  <span>: {t('8am - 5pm')}</span>
                </div>
                <div className={'footer-top-content-right-working-hours-item-container'}>
                  {t('Sunday')}
                  <span>: {t('9am - 3pm')}</span>
                </div>
              </div>
              <div className={'footer-top-content-right-contact-us-container'}>
                <div className={'footer-top-content-title footer-link'} onClick={handleContactUsClick}>{t('CONTACT US')}</div>
                <div className={'footer-top-content-right-contact-us-title'}>
                  131-25 31st Avenue, Flushing, NY 11354
                </div>
                <div className={'footer-top-content-right-contact-us-des'}>
                  {t('Phone')}: (716) 280-6630
                </div>
                <div className={'footer-top-content-right-contact-us-des'}>
                  {t('Phone')}: (929) 264-0678
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      <div className={'footer-bottom-container'}>
        © 2023 Olong restaurant supplies   | Powered by TruckXi
      </div>
    </div>
  )
}
