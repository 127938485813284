import React from 'react'
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {InputNumber, Checkbox, Input} from 'antd';

import './index.less'
import './mobile.less'

import noImage from '@/assets/img/icon/noImage.png'
import favoriteActiveIcon from '@/assets/img/icon/favoriteActive.png'

import {getFormatMoney} from '@/common';
import {PRODUCT_ITEM_BTN_TYPE_3} from "@/constants";

const defaultProps = {
  img: noImage,
  price: '',
  des: '',
  quantity: 1,
  btnText: 'Add to cart',
  totalPrice: 0,
  onProductItemClick: () => {},
  onBtnClick: () => {},
  productType: null,
  isShowPrice: true,
  checked: false,
  isCheck: false,
  isFormatPriceToMoney: false,
  isFavorite: false,
  isPromotion: false,
  onFavoriteIconClick: () => {},
  onQuantityChange: () => {},
  onRemarkInputChange: () => {},
  onCheckChange: () => {},
  stock: 999,
  isOverSaleable: false,
}
export default function FavoriteProductItem(props) {
  const options = Object.assign({}, defaultProps, props);

  const {t} = useTranslation()
  
  const commonState = useSelector(state => state.common)
  const rootClassName = commonState.isMobile ? 'favorite-mobile-container' : 'favorite-container'
  
  const {price, isShowPrice, isFormatPriceToMoney, isCheck, onCheckChange} = options
  const isOutOfStock = options.isOverSaleable ? false : options.stock <= 0
  if (isOutOfStock) {
    options.btnText = 'Out of stock'
  }
  const handleProductItemClick = () => {
    options.onProductItemClick()
  }
  const handleBtnClick = (event) => {
    if (isOutOfStock) {
      return;
    }
    event.preventDefault();
    event.stopPropagation()
    options.onBtnClick()
  }
  const handleFavoriteIconClick = (event) => {
    event.preventDefault();
    event.stopPropagation()
    options.onFavoriteIconClick()
  }
  const handleBottomClick = (event) => {
    event.preventDefault();
    event.stopPropagation()
  }
  return (
    <div className='favorite_row'>
      {options.isCheck && <Checkbox className='check_box' checked={options.checked} onChange={()=>{options.onCheckChange()}}/>}
      <div className={rootClassName} onClick={handleProductItemClick}>
      <div className={'favorite-img-container'}>
        {options.img ? <img src={options.img} alt="" /> : <img src={noImage} alt="" />}
        {options.isPromotion && <div className="favorite-promotion-icon-container">
          <img src={require('../../assets/img/icon/discountIcon.png')} alt="" />
        </div>}
        <div className={'favorite-right-container'}>
          {
            options.isFavorite &&
            <div className={'favorite-right-favorite-icon-container'} onClick={handleFavoriteIconClick}>
              <img src={favoriteActiveIcon} alt="" />
            </div>
          }
        </div>
      </div>
      <div className={'favorite-content-container'}>
        <div className={'favorite-des overflow-ellipsis-1'}>
          {options.des}
        </div>
        <div className={'favorite-price-quantity-row'}>
            {
              isShowPrice && 
              <div className={'favorite-price overflow-ellipsis-1'}>
                <span>${isFormatPriceToMoney ? getFormatMoney(price) : price}</span>
                <span className={'favorite-packageType'}>&nbsp;/ {options?.packageType}</span>
              </div>
            }
            {
              options.isFavorite &&
              <div onClick={handleBottomClick}>
                <InputNumber
                  className={'favorite-bottom-input'}
                  min={0}
                  max={options.isOverSaleable ? undefined : options?.stock}
                  disabled={isOutOfStock}
                  value={options.quantity}
                  onChange={options.onQuantityChange}
                />
              </div>
            }      
        </div>
       
          <div
            className={'favorite-bottom-container'}
            onClick={handleBottomClick}>
            <Input
              placeholder={t('Enter Remark')}
              value={options.Remark}
              onChange={options.onRemarkInputChange}
            />
            {
              options.isFavorite && <div className={isOutOfStock ? 'favorite-btn-disabled' : 'favorite-btn'} onClick={handleBtnClick}>
                {t(options.btnText)}
              </div>
            }
          </div>
        
      </div>
      </div>
    </div>
  )
}
